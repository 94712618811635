
(async () => {
  function getMonthShortName(monthNo) {
    const date = new Date();
    date.setMonth(monthNo - 1);
  
    return date.toLocaleString('en-US', { month: 'short' });
  };

  function convertToInternationalCurrencySystem (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue));

  };

  const fetchStatsSummary = async (sales24h) => {
    try {
      const statsResponse = await axios.get('https://api.pandastats.io/stats/summary');
      const ethPriceResponse = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');
      var ethPrice = 0;

      if (ethPriceResponse.status === 200) {
        const ethData = ethPriceResponse.data;
        ethPrice = ethData.USD;
      }

      if (statsResponse.status === 200) {
        const data = statsResponse.data;

        fetchVolumeSummary(data._24hvol, sales24h, data.FloorEth);


        const floor_eth = data.FloorEth;
        const fpe = document.getElementById('floor-price');
        fpe.innerHTML = `${floor_eth}`;

        const bid_eth = data.BidEth;
        const bpe = document.getElementById('bid-price');
        bpe.innerHTML = `${bid_eth}`; 

        const _24h_vol = data._24hvol;
        const v = document.getElementById('volume-24h');
        v.innerHTML = `${_24h_vol}`; 
        
        const mcap = convertToInternationalCurrencySystem((data.FloorEth * 9000) * ethPrice);
        const mc = document.getElementById('market-cap');
        mc.innerHTML = `${mcap}`; 

        const owners = data.UniqueOwners.toLocaleString(undefined,{ minimumFractionDigits: 0, maximumFractionDigits: 0 });
        const uo = document.getElementById('unique-owners');
        uo.innerHTML = `${owners}`; 

        const totvol = data.TotalVolume.toLocaleString(undefined,{ minimumFractionDigits: 0, maximumFractionDigits: 0 });
        const tv = document.getElementById('total-volume');
        tv.innerHTML = `${totvol}`; 

        

      } else {
        console.error('Error fetching data:', statsResponse.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  const fetchVolumeSummary = async (volume, sales24h, floorPrice) => {
    try {
      const volResponse = await axios.get('https://api.pandastats.io/stats/volume-summary');

      if (volResponse.status === 200) {
        const data = volResponse.data[0];
        const vol_delta = ((volume - data.PreviousDayVolume) / data.PreviousDayVolume) * 100;
        const vd = document.getElementById('volume-delta');
        
        if (vol_delta > 0) {
          vd.classList.add('text-success');
          vd.innerHTML = `<i class="uil uil-arrow-up"></i> ${vol_delta.toFixed(1)}%`;
        } else if (vol_delta < 0) {
          vd.classList.add('text-danger');
          vd.innerHTML = `<i class="uil uil-arrow-down"></i> ${vol_delta.toFixed(1)}%`;
        } else {
          vd.innerHTML = `${vol_delta.toFixed(1)}%`;
        }

        const sales_delta = ((sales24h - data.PreviousDaySalesCount) / data.PreviousDaySalesCount) * 100;
        const sd = document.getElementById('sales-count-delta');
        
        if (sales_delta > 0) {
          sd.classList.add('text-success');
          sd.innerHTML = `<i class="uil uil-arrow-up"></i> ${sales_delta.toFixed(1)}%`;
        } else if (sales_delta < 0) {
          sd.classList.add('text-danger');
          sd.innerHTML = `<i class="uil uil-arrow-down"></i> ${sales_delta.toFixed(1)}%`;
        } else {
          sd.innerHTML = `${sales_delta.toFixed(1)}%`;
        } 

        const floor_delta = ((floorPrice - data.PreviousDayFloor) / data.PreviousDayFloor) * 100;
        const fd = document.getElementById('floor-price-delta');
        fd.innerHTML = `${floor_delta}`;        

        if (floor_delta > 0) {
          fd.classList.add('text-success');
          fd.innerHTML = `<i class="uil uil-arrow-up"></i> ${floor_delta.toFixed(1)}%`;
        } else if (floor_delta < 0) {
          fd.classList.add('text-danger');
          fd.innerHTML = `<i class="uil uil-arrow-down"></i> ${floor_delta.toFixed(1)}%`;
        } else {
          fd.innerHTML = `${floor_delta.toFixed(1)}%`;
        }

      } else {
        console.error('Error fetching data:', volResponse.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const fetchDailyVolumeStats = async () => {
    try {
      
      const chart_container = document.querySelector('#daily-floor-chart');
      chart_container.style.display = 'none';

      const spinner = document.querySelector('#loading-spinner-daily-floor-chart');
      spinner.style.display = 'block';

      const response = await axios.get('https://api.pandastats.io/stats/volume');

      chart_container.style.display = 'block';
      spinner.style.display = 'none';

  
      if (response.status === 200) {
        const floorData = response.data;
        const timestamps = floorData.map(item => item.Timestamp);
        const floorPrices = floorData.map(item => item.FloorPriceEth);

        const options = {
          chart: {
            height: 285,
            type: 'area'
          },
          dataLabels: {
              enabled: false
          },
          stroke: {
              curve: 'smooth',
              width: 4
          },
          colors: ['#43d39e'],
          series: [
            {
              name: 'Floor Price',
              data: floorPrices
            }
          ],
          legend: {
              show: false
          },
          xaxis: {
            categories: timestamps,
            tickPlacement: "on",
            labels: {
              formatter: function (val) {
                var d = new Date(val),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate()

                return getMonthShortName(month) + '-' + day
              }
          }
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            tools: {
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
          }},
          yaxis: {
            min: 0.2,
            labels: {
                formatter: function (val) {
                    return val.toString().substring(0,4) + "e"
                }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [45, 100]
              },
        },
        };

        const chart = new ApexCharts(document.querySelector('#daily-floor-chart'), options);
        chart.render();

      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const renderSalesScatter = async () => {
    try {
      
      const date = new Date();
      date.setDate(date.getDate() - 7);
      const formattedDate = date.toISOString();

      const chart_container = document.querySelector('#sales-scatter-chart');
      chart_container.style.display = 'none';

      const spinner = document.querySelector('#loading-spinner-sales-scatter-chart');
      spinner.style.display = 'block';

      const response = await axios.get('https://api.pandastats.io/sales?$filter=timestamp gt ' + formattedDate + '&orderBy=Timestamp');


      chart_container.style.display = 'block';
      spinner.style.display = 'none';

  
      if (response.status === 200) {
        const floorData = response.data;
        const timestamps = floorData.map(item => item.Timestamp);
        const salesPrices = floorData.map(item => item.PriceEth);

        const options = {
          chart: {
            height: 285,
            type: 'scatter'
          },
          dataLabels: {
              enabled: false
          },
          stroke: {
              curve: 'smooth',
              width: 4
          },
          colors: ['#43d39e'],
          series: [
            {
              name: 'Sales Price',
              data: salesPrices
            }
          ],
          legend: {
              show: false
          },
          xaxis: {
            categories: timestamps,
            tickAmount: 10,
            tickPlacement: "on",
            labels: {
              formatter: function (val) {
                var d = new Date(val),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate()

                return getMonthShortName(month) + '-' + day
              }
          }
          },
          yaxis: {
            tickAmount: 7,
            labels: {
                formatter: function (val) {
                    return val.toString().substring(0,4) + "e"
                }
            }
          },
          tooltip: {
            
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              const tokenId = floorData[dataPointIndex].TokenId;
              return `<img src="https://prodftpserver.blob.core.windows.net/images/default/${tokenId}-1000x1000?r=${Math.floor(Math.random() * 100000)}" width="100px" class="img-thumbnail img-responsive"/>
              <div style="text-align:center;"><span style="font-size: 0.75rem;"><a href="#" class="text-muted d-inline-block">
                    ${series[seriesIndex][dataPointIndex].toLocaleString(undefined,{ minimumFractionDigits: 0, maximumFractionDigits: 2 })} Ξ</span></div>`
            }
          }
        };

        const chart = new ApexCharts(document.querySelector('#sales-scatter-chart'), options);
        chart.render();

      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const renderVolumeByMarketplace = async () => {
    try {
      const date = new Date();
      date.setDate(date.getDate() - 30);
      const formattedDate = date.toISOString();
  
      const chart_container = document.querySelector('#volume-by-marketplace-chart');
      chart_container.style.display = 'none';
  
      const spinner = document.querySelector('#loading-spinner-volume-by-marketplace');
      spinner.style.display = 'block';
  
      const response = await axios.get('https://api.pandastats.io/sales/marketplace');
  
      chart_container.style.display = 'block';
      spinner.style.display = 'none';
  
      if (response.status === 200) {
        const floorData = response.data;
        const shares = floorData.map(item => item.Volume);
        const marketplaces = floorData.map(item => item.Marketplace);
  
        const customColors = marketplaces.map(marketplace => {
          switch (marketplace) {
            case 'blur.io':
              return '#ff7326'; // red
            case 'opensea.io':
              return '#2081e2'; // green
            case 'gem.xyz':
              return '#f47e7c'; // blue
            case 'x2y2.io':
                return '#15b4f1'; // blue
            default:
              return '#' + Math.floor(Math.random()*16777215).toString(16);
          }
        });
        
  
        var options = {
          plotOptions: {
            pie: {
              donut: {
                size: '70%',
              },
              expandOnClick: false
            }
          },
          colors: ["#43d39e", "#5369f8", "#f77e53",  "#ffbe0b"],
          chart: {
            height: 270,
            type: 'donut',
          },
          legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'left',
            itemMargin: {
              horizontal: 6,
              vertical: 3
            }
          },
          series: shares,
          labels: marketplaces,
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }],
          tooltip: {
            theme: 'dark',
            y: {
              formatter: function(value) { return value + "k" }
            },
          }
        }
  
        // Remove the `colors` property from the `options.plotOptions.pie` object
        delete options.plotOptions.pie.colors;
  
        var chart = new ApexCharts(
          document.querySelector("#volume-by-marketplace-chart"),
          options
        );
  
        chart.render();
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  

  const fetchRecentSales = async () => {
    try { 

      const chart_container = document.querySelector('#pandas-sold-24h');
      chart_container.style.display = 'none';

      const spinner = document.querySelector('#loading-spinner-sales-table');
      spinner.style.display = 'block';

      const response = await axios.get('https://api.pandastats.io/sales?$top=100&orderBy=Timestamp desc');

      chart_container.style.display = 'block';
      spinner.style.display = 'none';


      if (response.status === 200) {       
        const sales_table = response.data;

        const currentTime = new Date();

        const filteredSales = $.grep(sales_table, function(n, i) {
          const currentTimeUTC = Date.UTC(currentTime.getUTCFullYear(), currentTime.getUTCMonth(), currentTime.getUTCDate(), currentTime.getUTCHours(), currentTime.getUTCMinutes(), currentTime.getUTCSeconds());
          const timestamp = new Date(n.Timestamp);
          const timeDifference = currentTimeUTC - timestamp;
          const hoursDifference = timeDifference / (1000 * 60 * 60);
        
          return hoursDifference <= 24;
        }); 

        const date = new Date();
        date.setDate(date.getDate() - 1);
        const formattedDate = date.toISOString();

        const ps24_response = await axios.get('https://api.pandastats.io/sales?$filter=timestamp gt ' + formattedDate + '&orderBy=Timestamp');

        const ps24h = document.getElementById('pandas-sold-24h');
        ps24h.innerHTML = `${ps24_response.data.length}`;

        fetchStatsSummary(filteredSales.length);

          $('#recent-sales-table').DataTable({
              order: [[0, 'desc']],
              data: sales_table,
              "bLengthChange": false,
              "pageLength": 10,
              columns: [
                  {
                    title: 'Date',
                    data: 'Timestamp',
                    render: function (data, type, row) {
                        if (type === "sort" || type === "type") {
                            return data;
                        }
                        
                        const now = moment();
                        const diffYears = now.diff(data, 'years');
                        const diffMonths = now.diff(data, 'months') % 12;
                        const diffDays = now.diff(data, 'days') % 30;
                        const diffHours = now.diff(data, 'hours') % 24;
                        const diffMinutes = now.diff(data, 'minutes') % 60;
                
                        if (diffYears > 0) {
                            return diffYears + 'y ago';
                        } else if (diffMonths > 0) {
                            return diffMonths + 'mo ago';
                        } else if (diffDays > 0) {
                            return diffDays + 'd ago';
                        } else if (diffHours > 0) {
                            return diffHours + 'h ago';
                        } else {
                          return diffMinutes + 'm ago';
                        }
                    }
                  },
                  { title: '', data: 'TokenId', orderable: false, className: 'dt-body-right p-1',
                    render: function (data, type, row, meta) {
                      return type === 'display'
                      ? '<img src="https://prodftpserver.blob.core.windows.net/images/default/' + data + '-1000x1000?r=' + Math.floor(Math.random() * 100000) + '" width="50px" data-bs-toggle="tooltip" data-bs-id="' + row.Id + '" data-bs-placement="top" data-tooltip="<div><img src=\'https://prodftpserver.blob.core.windows.net/images/default/' + data + '-1000x1000?r=' + Math.floor(Math.random() * 100000) + '\' width=\'150px\'/></div>" data-bs-html="true" />'
                      : data;
                    },
                  },
                  { title: 'Id', data: 'TokenId', className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      return type === 'display'
                          ? '<a href="panda.html?id=' + data + '">' + data + '</a>'
                          : data;
                    },
                  },
                  { title: 'Price (Ξ)', data: 'PriceEth', className: 'dt-body-right' },
                  { title: 'Price (USD)', data: 'PriceUsd', className: 'dt-body-right', render: $.fn.dataTable.render.number(',', '.', 2, '$') },
                  { title: 'Seller', data: 'From', className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      if (!data) {
                        return '-';
                      }

                      const owner_displayname = row.FromEnsAddress ? row.FromEnsAddress : data.substring(0,5);
                      return type === 'display'
                          ? '<a href="profile.html?address=' + data + '">' + owner_displayname + '</a>'
                          : data;
                    },
                  },
                  { title: 'Buyer', data: 'To', className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      if (!data) {
                        return '-';
                      }

                      const owner_displayname = row.ToEnsAddress ? row.ToEnsAddress : data.substring(0,5);
                      return type === 'display'
                          ? '<a href="profile.html?address=' + data + '">' + owner_displayname + '</a>'
                          : data;
                    },
                  },
                  { title: 'Royalties Paid', className: 'dt-body-right', data: 'RoyaltyUsd', render: $.fn.dataTable.render.number(',', '.', 2, '$') },
                  { title: '', data: 'TokenId', orderable: false, className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      return type === 'display'
                          ? '<a target="_blank" href="https://etherscan.io/tx/' + row.TxHash + '"><svg width="24px" height="24px"><image href="assets/images/etherscan.svg" width="24px" height="24px" style="fill: #6c757d;"></image></svg></a>'
                          : data;
                    },
                  },
              ],
              initComplete: function () {
                createTooltips('#recent-sales-table');
              },
              drawCallback: function() {
                createTooltips('#recent-sales-table');
              }
          });

          
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const fetchRecentListings = async () => {
    try { 

      const listings_container = document.querySelector('#recent-listings-table');
      listings_container.style.display = 'none';

      const spinner = document.querySelector('#loading-spinner-listings-table');
      spinner.style.display = 'block';

      const response = await axios.get('https://api.pandastats.io/listings?$top=100&orderby=CreatedDate%20desc');

      listings_container.style.display = 'table';
      spinner.style.display = 'none';


      if (response.status === 200) {       
        const listings_table = response.data;

          $('#recent-listings-table').DataTable({
              order: [[0, 'desc']],
              data: listings_table,
              "bLengthChange": false,
              "pageLength": 10,
              columns: [
                  {
                    title: 'Date',
                    data: 'CreatedDate',
                    render: function (data, type, row) {
                        if (type === "sort" || type === "type") {
                            return data;
                        }
                        
                        const now = moment();
                        const diffYears = now.diff(data, 'years');
                        const diffMonths = now.diff(data, 'months') % 12;
                        const diffDays = now.diff(data, 'days') % 30;
                        const diffHours = now.diff(data, 'hours') % 24;
                        const diffMinutes = now.diff(data, 'minutes') % 60;
                
                        if (diffYears > 0) {
                            return diffYears + 'y ago';
                        } else if (diffMonths > 0) {
                            return diffMonths + 'mo ago';
                        } else if (diffDays > 0) {
                            return diffDays + 'd ago';
                        } else if (diffHours > 0) {
                            return diffHours + 'h ago';
                        } else {
                          return diffMinutes + 'm ago';
                        }
                    }
                  },
                  { title: '', data: 'TokenId', orderable: false, className: 'dt-body-right p-1',
                    render: function (data, type, row, meta) {
                      return type === 'display'
                      ? '<img src="https://prodftpserver.blob.core.windows.net/images/default/' + data + '-1000x1000?r=' + Math.floor(Math.random() * 100000) + '" width="50px" data-bs-toggle="tooltip" data-bs-id="' + row.Id + '" data-bs-placement="top" data-tooltip="<div><img src=\'https://prodftpserver.blob.core.windows.net/images/default/' + data + '-1000x1000?r=' + Math.floor(Math.random() * 100000) + '\' width=\'150px\'/></div>" data-bs-html="true" />'
                      : data;
                    },
                  },
                  { title: 'Id', data: 'TokenId', className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      return type === 'display'
                          ? '<a href="panda.html?id=' + data + '">' + data + '</a>'
                          : data;
                    },
                  },
                  { title: 'Price (Ξ)', data: 'PriceEth', className: 'dt-body-right', 
                    render: function (data, type, row, meta) {
                      return type === 'display'
                          ? '<a href="' + row.Url + '" target="_blank" class="text-muted" title="Buy now"><button type="button" class="btn btn-white btn-xs" style="width:100px;"><img src="assets/images/marketplaces/' + row.Marketplace + '.svg" width="18px" style="padding-right:5px;margin-left:-5px;"/>' + row.PriceEth.toLocaleString(undefined,{ minimumFractionDigits: 0, maximumFractionDigits: 4 }) + 'Ξ</button></a>'
                          : data;
                    },
                  },
                  
                  { title: 'Rank', data: 'Rank', className: 'dt-body-right' },
                  { title: 'Points', data: 'PointsBalance', className: 'dt-body-right' },
                  { title: 'Seller', data: 'Seller', className: 'dt-body-right',
                    render: function (data, type, row, meta) {
                      if (!data) {
                        return '-';
                      }

                      const owner_displayname = row.SellerEnsAddress ? row.SellerEnsAddress : data.substring(0,5);
                      return type === 'display'
                          ? '<a href="profile.html?address=' + data + '">' + owner_displayname + '</a>'
                          : data;
                    },
                  },
              ],
              initComplete: function () {
                createTooltips('#recent-listings-table');
              },
              drawCallback: function() {
                createTooltips('#recent-listings-table');
              }
          });

          
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  function createTooltips(entity) {
    const tooltips = $(entity).find('[data-bs-id]');
  
    tooltips.each(function () {
      const content = $(this).attr('data-tooltip');
      const id = $(this).attr('data-bs-id');
  
      $(this).attr('data-bs-html', true);
      new bootstrap.Tooltip(this, {
        id: id,
        placement: 'top',
        html: true,
        sanitizeFn: (content) => content,
        title: content
      });
    });
  }
  

  
  fetchDailyVolumeStats();
  renderVolumeByMarketplace();
  renderSalesScatter();
  fetchRecentSales();
  fetchRecentListings();


})();